<template>
  <div>
    <el-table
      height="360"
      :data="displayDatas"
      tooltip-effect="dark"
      stripe
      style="width: 100%"
    >
      <el-table-column type="index" width="50"></el-table-column>
      <el-table-column label="姓名" width="120">
        <template slot-scope="scope">
          <div class="xa-cell">
            {{ scope.row.name || '-' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="身份证号" width="200">
        <template slot-scope="scope">
          <div class="xa-cell">
            {{ scope.row.idcard || '-' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="手机号码" width="200">
        <template slot-scope="scope">
          <div class="xa-cell">
            {{ scope.row.mobile || '-' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="失败信息" width="210">
        <template slot-scope="scope">
          <div class="xa-cell" style="color:red">
            {{ scope.row.message || '-' }}
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="xa-cell xa-txt-placeholder">
      <div class="xa-flex">
        <!-- 已成功导入记录：<span class="xa-txt-blue">({{ datas.length }})</span> 失败记录：5 -->
      </div>
      <el-pagination
        layout="prev, pager, next"
        :page-count="totoPid"
        :current-page="pid + 1"
        @current-change="onChangePage"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import DisplayProductTable from './DisplayProductTable.vue'
export default {
  extends: DisplayProductTable,
}
</script>
<style lang="scss" scoped></style>
